<template>
  <div v-if="!isSending" class="list mb-16">
    <template v-if="stripeAccount && stripeAccount.charges_enabled">
      <no-data-info
        v-if="!subscriptionsExist"
        :description="$trans('no_subscriptions_info')"
        :button-text="$trans('subscriptions_add_title')"
        @click="handleAddSubscription"
      />
      <template v-else>
        <subscriptions-filter
          @filtersChanged="filtersChanged"
          @filtersRestored="filtersRestored"
        />
        <v-data-table
          v-model="selected"
          :footer-props="footerProps"
          :headers="headers"
          :items="items"
          :loading="isLoadingTable"
          :loading-text="$trans('loading')"
          :no-data-text="$trans('nothing_found_here')"
          :no-results-text="$trans('nothing_found_here')"
          :options.sync="tableOptions"
          :server-items-length="total"
          :show-select="!noData"
          class="calendesk-datatable"
          mobile-breakpoint="960"
        >
          <template #[`header.data-table-select`]>
            <div class="text-center">
              <v-simple-checkbox
                v-model="selectAllState"
                :ripple="false"
                :indeterminate="isIndeterminateForSelectAll"
                color="primary"
                @input="selectAllItemsOnChange"
              />
            </div>
          </template>

          <template #[`item`]="{ item }">
            <subscriptions-row
              :item="item"
              :selection-map="(item) => item"
              :is-selected="
                isRowSelected({
                  id: item.id,
                })
              "
              @selectOnChange="itemRowEventOnSelectChange"
            />
          </template>
        </v-data-table>
        <table-footer-menu
          :on-select-actions="onSelectActions"
          :selected="selected"
          :selection-map="(item) => item"
        />
      </template>
    </template>
    <template v-else>
      <v-container>
        <v-row>
          <v-col>
            <no-data-info
              v-if="loggedUserIsAdmin"
              :description="
                $trans('subscriptions_stripe_account_missing_description')
              "
              :button-text="$trans('go_to_stripe')"
              :redirect-to-route="{
                name: 'integrations',
                params: { integration: 'stripe' },
              }"
            />
            <no-data-info
              v-else
              :description="
                $trans('subscriptions_stripe_account_missing_description')
              "
              hide-button
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import statsActions from "@/calendesk/mixins/statsActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import subscriptionActions from "@/calendesk/mixins/subscriptionActions";
import SubscriptionsFilter from "@/views/dashboard/pages/Offer/Subscriptions/components/SubscriptionsFilter.vue";
import SubscriptionsRow from "@/views/dashboard/pages/Offer/Subscriptions/components/SubscriptionsRow.vue";

export default {
  components: {
    SubscriptionsRow,
    SubscriptionsFilter,
    TableFooterMenu,
    NoDataInfo,
  },
  mixins: [subscriptionActions, statsActions, sharedList],
  data() {
    return {
      cacheId: "subscriptions-list",
      requestActionName: "fetchAllSubscriptionsList",
      statsMode: this.$helpers.statsMode.subscriptions,
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          sortable: true,
          value: "id",
          class: "header-style",
        },
        {
          text: this.$trans("subscription"),
          value: "subscription_stripe_product",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("wants_invoice_title"),
          value: "wants_invoice",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
  computed: {
    onSelectActions() {
      return [
        {
          title: { single: "remove", many: "remove" },
          action: this.handleDeleteUserSubscriptions,
          target: "product",
          class: "accent_light--text",
        },
      ];
    },
  },
  watch: {
    getRefreshSubscriptionsListStatus(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
};
</script>
