<template>
  <div v-if="displayedServices.length > 0">
    <v-chip
      v-for="service in displayedServices"
      :key="service.id"
      x-small
      class="mr-1"
    >
      <span>{{ `${getServiceName(service)}` | truncate(45) }}</span>
    </v-chip>
    <v-chip v-if="showMoreChip" x-small class="mr-1">
      +{{ extraServicesCount }} {{ $trans("more") }}...
    </v-chip>
  </div>
</template>
<script>
import {
  getServiceAndItsTypeForId,
  getServiceName,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  props: {
    services: {
      type: Array,
      default: null,
    },
    serviceIds: {
      type: Array,
      default: null,
    },
    maxServicesCount: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      parsedServices: [],
    };
  },
  computed: {
    displayedServices() {
      return this.parsedServices.slice(0, this.maxServicesCount);
    },
    totalServicesCount() {
      return this.services
        ? this.services.length
        : this.serviceIds
        ? this.serviceIds.length
        : 0;
    },
    showMoreChip() {
      // Determine if the "+more" chip should be shown based on the total count of services or service IDs
      return this.totalServicesCount > this.maxServicesCount;
    },
    extraServicesCount() {
      return Math.max(this.totalServicesCount - this.maxServicesCount, 0);
    },
  },
  watch: {
    services: {
      handler: "loadServices",
      immediate: true,
    },
    serviceIds: {
      handler: "loadServicesById",
      immediate: true,
    },
  },
  methods: {
    getServiceName,
    loadServices() {
      if (this.services) {
        const services = this.services;
        this.parsedServices = services
          .sort((a, b) => a.id - b.id)
          .slice(0, this.maxServicesCount);
      }
    },
    loadServicesById() {
      if (this.serviceIds) {
        const services = [];

        for (let id of this.serviceIds) {
          const service = getServiceAndItsTypeForId(id, null, true);
          if (service) {
            services.push(service);
          }
        }

        // Correctly sorting the services by category's order_position, then by service's order_position
        this.parsedServices = services
          .sort((a, b) => {
            // First, sort by category's order_position
            if (a.category.order_position !== b.category.order_position) {
              return (
                parseFloat(a.category.order_position) -
                parseFloat(b.category.order_position)
              );
            }

            // If category's order_position are the same, then sort by service's order_position
            return parseFloat(a.order_position) - parseFloat(b.order_position);
          })
          .slice(0, this.maxServicesCount);
      }
    },
  },
};
</script>
