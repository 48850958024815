<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatuses"
          :items="availableSubscriptionStatusFilters"
          :label="$trans('status')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <services-field
          v-model="selectedFiltersServices"
          dense
          small-chips
          multiple
          deletable-chips
          :disabled="disabled"
          return-ids
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";

export default {
  components: { ServicesField },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "subscriptions-list-filters",
      selectedFiltersSearch: null,
      selectedFiltersStatuses: null,
      selectedFiltersServices: null,
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersStatuses: this.selectedFiltersStatuses,
        selectedFiltersServices: this.selectedFiltersServices,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersStatuses &&
        this.selectedFiltersStatuses.length > 0
      ) {
        data.status_ids = this.selectedFiltersStatuses.join(",");
      }

      if (
        this.selectedFiltersServices &&
        this.selectedFiltersServices.length > 0
      ) {
        data.service_ids = this.selectedFiltersServices.join(",");
      }

      return data;
    },
  },
};
</script>
