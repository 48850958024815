<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__55-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("subscription") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="mb-2">
          <div class="d-flex align-center justify-end justify-md-start mb-4">
            <span>{{ item.name }}</span>
            <v-chip
              x-small
              :color="$helpers.getStatusColor(item.status).color"
              :class="$helpers.getStatusColor(item.status).class"
              class="ml-2"
            >
              {{ $trans(item.status) }}
            </v-chip>
          </div>
          <div v-if="item.service_limit">
            <span>{{ item.service_limit | plural("booking", true) }} </span>
            <span v-if="item.recurring_interval"
              >({{ $trans("recurrent_bookings_reset") }}
              {{
                item.interval_count | plural(item.recurring_interval, true)
              }})</span
            >
          </div>
        </div>
        <service-chips
          :service-ids="services.map((service) => service.id)"
          class="ml-n1 mt-2 mb-2"
        />
        <template v-if="item.price">
          <span v-if="item.price.product">{{ item.price.product.name }}</span
          ><br />
          <div class="mt-2 mb-2">
            <span class="font-weight-medium"
              >{{ $trans("subscription_payments") }}:</span
            >&nbsp;
            <span>{{ item.price.unit_amount | money }}</span>
            <span v-if="item.price.recurring_interval">
              ({{ $trans("recurrent_payment_each") }}
              {{
                item.price.interval_count
                  | plural(item.price.recurring_interval, true)
              }})</span
            >
          </div>
        </template>
        <div v-if="item.success_url" class="mt-2 mb-2">
          {{ $trans("subscription_success_url") }}:
          <a :href="item.success_url" target="_blank" @click.stop>{{
            item.success_url
          }}</a>
        </div>
        <div v-if="item.cancel_url" class="mt-2 mb-2">
          {{ $trans("subscription_cancel_url") }}:
          <a :href="item.cancel_url" target="_blank" @click.stop>{{
            item.cancel_url
          }}</a>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("wants_invoice_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center">
          <v-icon
            :color="item.wants_invoice ? 'green' : 'light'"
            class="mr-2"
            v-text="item.wants_invoice ? '$check-circle' : '$close-circle'"
          >
            $check-circle
          </v-icon>

          <div v-if="item.tax">{{ $trans("tax_label") }}: {{ item.tax }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("created_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ item.created_at | dateTime($helpers.hourDayMonthAndYearDate) }}
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <subscriptions-list-buttons :is-hovered="isHovered" :item="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import SubscriptionsListButtons from "@/views/dashboard/pages/Offer/Subscriptions/components/SubscriptionsListButtons.vue";
import ServiceChips from "@/components/ServiceChips.vue";

export default {
  components: {
    ServiceChips,
    SubscriptionsListButtons,
  },
  mixins: [itemRowActions],
  data() {
    return {
      services: this.item.services ?? [],
    };
  },
  watch: {
    item(item) {
      this.services = item.services ?? [];
    },
  },
};
</script>
