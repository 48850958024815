import { mapActions, mapGetters } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";
import { getUnique, pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  mixins: [planActions],
  computed: {
    ...mapGetters({
      getRefreshSubscriptionsListStatus:
        "subscriptions/getRefreshSubscriptionsListStatus",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllSubscriptionsList: "subscriptions/fetchAllSubscriptions",
      deleteSubscription: "subscriptions/delete",
      refreshSubscriptionsList: "subscriptions/refreshSubscriptionsList",
      searchSubscriptions: "subscriptions/searchSubscriptions",
    }),
    handleEditSubscription(item) {
      if (this.canUseSubscriptions) {
        this.$root.$emit("openManageUserSubscriptionModal", {
          title: this.$trans("subscriptions_update_title"),
          subscription: item,
          ctaButtonIcon: "$save",
          ctaButtonTitle: this.$trans("save"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleDeleteUserSubscriptions(subscriptions) {
      pushEvent("removeSubscription");

      if (this.canUseSubscriptions) {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          confirmAction: () => {
            this.setCommonDialogLoader(true);
            const requests = [];
            subscriptions.forEach((subscription) => {
              requests.push(this.deleteSubscription(subscription.id));
            });

            Promise.all(requests)
              .catch((error) => {
                errorNotification(
                  "remove_subscription_not_possible_error_notification",
                  error,
                  false,
                );
              })
              .finally(() => {
                this.refreshSubscriptionsList();

                // Reload subscriptions
                this.searchSubscriptions({ order_by: "id", ascending: 0 });

                this.setCommonDialogLoader(false);
                this.closeConfirmDialog();
              });
          },
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    handleAddSubscription() {
      if (this.canUseSubscriptions) {
        this.$root.$emit("openManageUserSubscriptionModal", {
          title: this.$trans("subscriptions_add_title"),
          subscription: null,
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
  },
};
